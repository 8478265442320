import store from '@/store'
import { useRouter } from '@core/utils/utils'

export const useCheckStudent = () => {
  // eslint-disable-next-line arrow-body-style
  // const checkStudent = studentCode => {
  //   return store.dispatch('manage-student/checkStudent', { studentCode })
  // }
 const findOneStudent = (...args) => store.dispatch('manage-student/findOneStudent', ...args)
 const findStudents = (...args) => store.dispatch('manage-student/findStudents', ...args)
  const checkStudent = (...args) => store.dispatch('manage-student/checkStudent', ...args)
 const studentCount = () => store.dispatch('manage-student/studentCount')
 const getDailyreport = (...args) => store.dispatch('manage-student/getDailyreport', ...args)
 const getTermreport = (...args) => store.dispatch('manage-student/getTermreport', ...args)
 const resetReport = (...args) => store.dispatch('manage-student/resetReport', ...args)
  

  return {
    findOneStudent,
    findStudents,
    checkStudent,
    studentCount,
    getDailyreport,
    getTermreport,
    resetReport
  }
}
